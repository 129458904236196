import React from 'react'
import {useIntl} from "gatsby-plugin-intl"
import Layout from '../../components/layout'
import Header from '../../components/header'
import InfoLine from '../../components/infoline'
import {graphql} from 'gatsby'


const AdditiveFertigung = (props) => {
  const intl = useIntl()
  return(

  <Layout>
    
    <Header 
      title={intl.formatMessage({ id: "3dMaterialien.Dm-UeberSchrift1" })}
      text={intl.formatMessage({ id: "3dMaterialien.Dm-UeberSchrift2" })}
    />
    <div className='row'>
      <div className='col-2'>

      </div>
      <div className='col-8'>

        <p> {intl.formatMessage({ id: "3dMaterialien.Dm-UeberSchrift3" })}
        </p>
        <p>
        
        {intl.formatMessage({ id: "3dMaterialien.Dm-UeberSchrift4" })}

        </p>
      </div>

    </div>

    

    <InfoLine
      title={intl.formatMessage({ id: "3dMaterialien.Dm-StandardFilamente" })}
      distanceLeft='col-2'
      text={intl.formatMessage({ id: "3dMaterialien.Dm-StandardFilamenteText" })}
    />
    <InfoLine
      title={intl.formatMessage({ id: "3dMaterialien.Dm-StandardFilamenteBio" })}
      distanceLeft='col-2'
      text={intl.formatMessage({ id: "3dMaterialien.Dm-StandardFilamenteBioT1" })}
    />
    <InfoLine
      title={intl.formatMessage({ id: "3dMaterialien.Dm-StandardFilamenteSpezial" })}
      distanceLeft='col-2'
      text={intl.formatMessage({ id: "3dMaterialien.Dm-StandardFilamenteSpezialT1" })}
    />
    <InfoLine
      title={intl.formatMessage({ id: "3dMaterialien.Dm-SLSMaterial" })}
      distanceLeft='col-2'
      text={intl.formatMessage({ id: "3dMaterialien.Dm-SLSMaterialT1" })}
    />

    <InfoLine
      title={intl.formatMessage({ id: "3dMaterialien.Dm-SlAMaterial" })}
      distanceLeft='col-2'
      text={intl.formatMessage({ id: "3dMaterialien.Dm-SLAMaterialT1" })}
    />
    
    <InfoLine
      title={intl.formatMessage({ id: "3dMaterialien.Dm-Compounding" })}
      distanceLeft='col-2'
      text={intl.formatMessage({ id: "3dMaterialien.Dm-CompoundingText" })}
    />



    </Layout>
  )
}


export default AdditiveFertigung;


//fluidImage is defined on imagePage
export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "2.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`